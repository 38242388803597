import { jsx as _jsx } from "react/jsx-runtime";
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import '@app/locale';
import App from './App';
import store from './store/index';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
// <StrictMode>
_jsx(Provider, { store: store, children: _jsx(App, {}) })
// </StrictMode>
);
