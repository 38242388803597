const REGEXP_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
const REGEXP_LATIN_SYMBOLS = /^([A-Za-z\s]+)$/i;
const REGEXP_CARD_NUMBER = /^(\d{4} \d{4} \d{4} \d{4})$/;
const REGEXP_EXPIRE_DATE = /^(\d\d\/\d\d)$/;
const REGEXP_CVV = /^(\d\d\d)$/;
// should have at least 1 space char
const REGEXP_CARD_HOLDER = /^[A-Z]+[\s][A-Z]+$/;
const REGEXP_PASSWORD = /^[A-Za-z0-9!@#$%^&*()_\-+={}\\/:;"'<>,.?~`]+$/;
const REGEXP_DISPLAY_NAME = /^([A-Za-z0-9\s]+)$/i;
const REGEXP_USERNAME_SPECIAL_CHAR = /^[_.]$/;
const REGEXP_USERNAME = /^([A-Za-z0-9._]+)$/i;
const MASK_EXPIRE_DATE = [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
];
const MASK_CVV = [
    /\d/,
    /\d/,
    /\d/,
];
const MASK_CARD_NUMBER = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];
export { REGEXP_EMAIL, REGEXP_CARD_NUMBER, REGEXP_EXPIRE_DATE, REGEXP_CVV, MASK_EXPIRE_DATE, MASK_CARD_NUMBER, MASK_CVV, REGEXP_PASSWORD, REGEXP_CARD_HOLDER, REGEXP_USERNAME_SPECIAL_CHAR, REGEXP_DISPLAY_NAME, REGEXP_USERNAME, REGEXP_LATIN_SYMBOLS, };
