import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';
import { IsDefined } from '@utils/js-ts';
/**
 *
 * @param pageTitle
 * @param t
 * @param withSuffix
 * Set to **false** if you want to create a title without default
 * ` - Endrex` suffix at the end of the title.
 *
 * e.g. by default with `withSuffix = true`
 * the page title will look something like this `${pageTitle} - Endrex`
 * @returns
 */
const createDocumentTitle = (pageTitle, t, withSuffix = true) => (withSuffix ? t('page_title', { pageTitle, ns: 'common' }) : pageTitle);
const setDocumentTitle = (pageTitle, t, withSuffix = true) => {
    document.title = createDocumentTitle(pageTitle, t, withSuffix);
};
const getDocumentTitleFromMatches = (matches, t) => {
    let title;
    for (let i = 0; i < matches.length; i += 1) {
        const match = matches[i];
        const matchHandle = match.handle;
        if (matchHandle && matchHandle.getDisplayName) {
            title = matchHandle.getDisplayName(t);
        }
    }
    return title;
};
const useDocumentTitleSetter = () => {
    const { t } = useTranslation();
    const matches = useMatches();
    const documentTitle = getDocumentTitleFromMatches(matches, t);
    if (!IsDefined(documentTitle)) {
        setDocumentTitle(t('pages.not_found', { ns: 'common' }), t);
    }
    else {
        setDocumentTitle(documentTitle, t);
    }
};
export default useDocumentTitleSetter;
export { createDocumentTitle, setDocumentTitle, };
