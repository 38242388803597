import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useAppSelector } from '@app/store/Hooks';
import { useGetMeQuery } from '@shared/api/current-user';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import UnauthorizedPage from './UnauthorizedPage';
const RouteAuthCheckWrapper = ({ children }) => {
    const { navigate, routes } = useAppNavigation();
    const { isError, data } = useGetMeQuery();
    const { auth } = useAppSelector(state => state.auth);
    useEffect(() => {
        if (!auth || isError) {
            navigate(routes.ROOT);
        }
    }, [auth, isError, data, navigate, routes]);
    if (isError) {
        return _jsx(UnauthorizedPage, {});
    }
    return (children);
};
export default RouteAuthCheckWrapper;
